// Bibliotecas externas
import React from 'react'

import DashboardIcon from '@material-ui/icons/DashboardRounded'
import LogoutIcon from '@material-ui/icons/ExitToAppRounded'
import ArquivosIvon from '@material-ui/icons/InsertDriveFile'
import CotacoesIcon from '@material-ui/icons/LocalAtm'
import FaturamentoIcon from '@material-ui/icons/ReceiptRounded'
import ConfiguracoesIcon from '@material-ui/icons/SettingsRounded'
import PainelEnergiaIcon from '@material-ui/icons/ViewList'
import AgentesIcon from 'mdi-material-ui/AccountBoxMultiple'
import CurtoPrazoIcon from 'mdi-material-ui/AlphaCbox'
import LongoPrazoIcon from 'mdi-material-ui/AlphaLbox'
import PedagioIcon from 'mdi-material-ui/BoomGate'
import FechamentosIcon from 'mdi-material-ui/PlaylistCheck'
import ContratosIcon from 'mdi-material-ui/TextBoxMultipleOutline'
import MedicoesIcon from 'mdi-material-ui/Counter'
import RelatorioIcon from '@material-ui/icons/Assessment'
import PerfilIcon from '@material-ui/icons/AccountCircleRounded'
import PldIcon from 'mdi-material-ui/AlphaPbox'
import TimelineIcon from '@material-ui/icons//Timeline'
import ConsolidadoIcon from '@material-ui/icons/ChromeReaderMode'
import EcoIcon from '@material-ui/icons/Eco'
import ReceiptIcon from '@material-ui/icons/Receipt'

// Imports absolutos internos
import { MenuLinkEngecomp } from 'components/Agentes/Links/Engecomp'
import { MenuLinkFaturasThymos } from 'components/Agentes/Links/FaturasThymos'
import { MenuLinkNottus } from 'components/Agentes/Links/NottusMeteorologia'

const eGridThymos = process.env.REACT_APP_COMPANY_NAME == 'Thymos'
const eGridElectric = ['Electric', 'ACME', 'eGrid'].includes(
  process.env.REACT_APP_COMPANY_NAME
)

const clientItems = [
  {
    icon: <DashboardIcon />,
    text: 'Dashboard',
    path: '/app/dashboard',
    divider: true,
  },
  {
    icon: <PedagioIcon />,
    text: 'Faturas de uso do sistema',
    path: '/app/pedagio',
  },
  {
    icon: <RelatorioIcon />,
    text: 'Relatórios',
    path: '/app/relatorios',
  },
  {
    icon: <ConsolidadoIcon />,
    text: 'Relatórios consolidados',
    path: '/app/consolidados',
  },
  {
    icon: <ContratosIcon />,
    text: 'Contratos de Energia',
    path: '/app/contratos',
  },
  {
    icon: <PldIcon />,
    text: 'PLD',
    path: '/app/pld',
  },
  {
    icon: <TimelineIcon />,
    text: 'Projeções',
    path: '/app/projecoes',
  },
  {
    icon: <CotacoesIcon />,
    text: 'Cotações',
    path: '/app/cotacoes',
  },
  ...(eGridElectric
    ? [
        {
          icon: <EcoIcon />,
          text: 'Sustentabilidade',
          path: '/app/sustentabilidade',
          test: true,
        },
      ]
    : []),
  ...(eGridThymos
    ? [MenuLinkEngecomp, MenuLinkFaturasThymos, MenuLinkNottus]
    : []),
  {
    icon: <ReceiptIcon />,
    text: 'Coleta de Faturas',
    path: '/app/coleta_faturas',
    coleta: true,
  },
  {
    icon: <MedicoesIcon />,
    text: 'Medições',
    path: '/app/medicoes',
    divider: true,
  },
  {
    icon: <PerfilIcon />,
    text: 'Minha conta',
    path: '/app/conta/perfil',
  },
  {
    icon: <LogoutIcon />,
    text: 'Sair',
    path: '/session/destroy',
    divider: true,
  },
]

const items = [
  {
    icon: <DashboardIcon />,
    text: 'Dashboard',
    path: '/app/dashboard',
    divider: true,
  },
  {
    icon: <AgentesIcon />,
    text: 'Agentes',
    path: '/app/agentes',
  },
  {
    icon: <PainelEnergiaIcon />,
    text: 'Painel de Energia',
    path: '/app/painel',
  },
  {
    icon: <ContratosIcon />,
    text: 'Contratos de Energia',
    path: '/app/contratos',
  },
  {
    icon: <FechamentosIcon />,
    text: 'Fechamentos',
    path: '/app/fechamentos',
  },
  {
    icon: <PldIcon />,
    text: 'PLD',
    path: '/app/pld',
  },
  {
    icon: <CotacoesIcon />,
    text: 'Cotações',
    path: '/app/cotacoes',
    divider: true,
  },
  {
    icon: <CurtoPrazoIcon />,
    text: 'Curto Prazo',
    path: '/app/operacoes',
  },
  {
    icon: <LongoPrazoIcon />,
    text: 'Longo Prazo',
    path: '/app/longoprazo',
  },
  {
    icon: <FaturamentoIcon />,
    text: 'Faturamento',
    path: '/app/faturamento',
    divider: true,
  },
  {
    icon: <PedagioIcon />,
    text: 'Faturas de uso do sistema',
    path: '/app/pedagio',
  },
  {
    icon: <RelatorioIcon />,
    text: 'Relatórios',
    path: '/app/relatorios',
  },
  ...(eGridElectric
    ? [
        {
          icon: <EcoIcon />,
          text: 'Sustentabilidade',
          path: '/app/sustentabilidade',
          test: true,
        },
      ]
    : []),
  {
    icon: <ConsolidadoIcon />,
    text: 'Relatórios consolidados',
    path: '/app/consolidados',
    divider: true,
  },
  {
    icon: <ConfiguracoesIcon />,
    text: 'Configurações',
    path: '/app/configuracoes',
  },
  {
    icon: <PerfilIcon />,
    text: 'Minha conta',
    path: '/app/conta/perfil',
  },
  {
    icon: <ArquivosIvon />,
    text: 'Arquivos',
    path: '/app/arquivos',
    test: true,
  },
  {
    icon: <LogoutIcon />,
    text: 'Sair',
    path: '/session/destroy',
    divider: true,
  },
]

export { clientItems, items }
